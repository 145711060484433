.claim-details {
  .equal.fields {
    .field:last-child {
      text-align: left;
    }
  }
  #case-numbers {
    position: relative;
    .super-field {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .input {
        width: 60% !important;
      }
    }
    button.edit-button {
      position: absolute;
      left: 129px;
      top: -35px;
      padding: 8px;
    }
  }
}

.claim-attachment {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  .fields {
    display: flex;
    justify-content: space-between;
    &.smart-label {
      margin: 0 !important;
      padding: 10px;
    }
    .field {
      label {
        white-space: nowrap;
        max-width: calc(100vh * 0.35);
        overflow: hidden;
        text-overflow: ellipsis;
        overflow-x: hidden;
        display: block;
      }
    }
  }

  .fields:hover {
    background-color: #d3d3d34d;
  }
}

div.checkboxes-label {
  margin: 10px 0 15px;
}

textarea.employee-comment {
  width: 100%;
  padding: 10px;
  border: none;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.13) inset;

  &:focus-visible {
    outline: none;
    box-shadow: 0px 0px 0px 1px #51a7e8 inset;
  }
}

.acceptation-column-header {
  width: 100%;

  .header {
    text-align: center;
    margin-bottom: 5px;
  }

  .tiles {
    display: flex;

    .acceptation-column-tile {
      flex: 1;
      text-align: center;
      border-right: 1px solid black;
      &:nth-child(1) {
        border-left: 1px solid black;
      }
    }
  }
}

.cost-group-change .ui.selection.dropdown {
  div.text {
    height: 14px;
  }
  i.dropdown.icon {
    padding: 10px;
  }
}
