.straight-font {
  font-family: sans-serif !important;
  font-weight: bold !important;
}

.block-menu-item i,
.block-menu-item .loader {
  margin-top: -4px !important;
}

.button-container {
  width: 100%;
}
