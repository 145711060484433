$defaultWidth: 100%;
$defaultHeight: 100%;
$fontWeight: bold;
$flex: flex;
$justifyContentCenter: center;
$flexDirectionRow: row;
$flexDirectionColumn: column;

.SpeederHeader {
    display: $flex;
    font-size: 1.5rem;
    font-weight: $fontWeight;
    height: $defaultHeight;
    justify-content: $justifyContentCenter;
    margin-top: -3px;
    max-height: 15px;
}

.SppederContainer {
    display: $flex;
    justify-content: $justifyContentCenter;
    margin-bottom: unset;
    width: $defaultWidth;
    .ButtonContainer {
        display: $flex;
        flex-direction: $flexDirectionRow;
        height: $defaultHeight;
        justify-content: flex-end;
        margin-right: -9px;
     }
    
     .ButtonContainerWhenDatePickerExistVENTESQ {
        margin-right: -9px;
        padding-left: 170px;
        padding-top: 6px;
     }
    
     .ButtonContainerWhenDatePickerExistLIGFACTQ {
        margin-right: -9px;
        margin-top: -8px;
        padding-left: 170px;
     }
    
    .DatePickerContainerVENTESQ {
        align-items: flex-start;
        display: $flex;
        flex-direction: $flexDirectionRow;
        margin-top: 4px;
    }
    
    .DatePickerContainerLIGFACTQ {
        align-items: flex-start;
        display: $flex;
        flex-direction: $flexDirectionRow;
        margin-top: -10px;
    }
    
    .DescriptionContainer {
        align-items: center;
        display: $flex;
        justify-content:$justifyContentCenter;
        flex-direction: $flexDirectionColumn;
        height: $defaultHeight;
        width: $defaultWidth;
    }
    
    .DescriptionContainerWithDatePickers {
        align-items: center;
        display: $flex;
        justify-content:$justifyContentCenter;
        flex-direction: $flexDirectionColumn;
        height: $defaultHeight;
        width: $defaultWidth;
    }
    
    .DetailsContainer {
        display: $flex;
        flex-direction: $flexDirectionRow;
    }
    
    .SpeederDescription {
        width: $defaultWidth;
    }
    
    .SpeederInput {
        display: $flex!important;
        flex-direction: $flexDirectionColumn;
    }
    
    .SpeederLabel {
        font-weight: $fontWeight;
        width: $defaultWidth;
    }
    
    .LoaderButton {
        max-width: 88.125px;
        width:100%
    }    
}