div#contact-info {
  padding: 25px;

  .ui.header {
    font-size: 2rem !important;
  }

  .orange {
    color: #e3480a;
  }

  .value {
    font-size: 1.8rem;
    font-weight: 600;
  }

  .info-text {
    margin: 25px auto;
    font-size: 1.5rem;
  }

  .tile-container {
    display: flex;
    align-items: center;
    margin: 40px 0;
  }

  .icon-container {
    margin-right: 10px;
  }

  .tile-header {
    text-transform: uppercase;
    font-weight: 700;
  }

  small {
    font-size: 90%;
  }
}
