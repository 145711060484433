.CheckobxContainer {
    font-weight: bold;
    padding-left: 0.5vw;
}

.LabelProductName {
    font-weight: bold;
    margin-left: 10px;
}

.SearchInput {
    display: inline-block;
    margin-left: 8px;
}