.right,
.left {
  margin-top: 10px;
  margin-bottom: 10px;
}

.comment-wrapper {
  padding: 8px;
  border-radius: 8px;
  font-size: 15px;
}

.right {
  justify-content: end !important;
  text-align: start;

  .comment-wrapper {
    border-bottom-right-radius: 0;
    background-color: #e3480a;
    color: #fff;
  }
}

.left {
  justify-content: start !important;

  .comment-wrapper {
    border-bottom-left-radius: 0;
    background-color: #bdbec0;
  }
}

.info {
  position: absolute;
  top: -10px;
  font-size: 10px;

  .name {
    font-size: 11px;
    font-weight: 600;
  }

  &.supplier .name {
    color: blue;
  }

  &.warehouse .name {
    color: red;
  }

  &.warehouse_supplier .name {
    color: #e3480a;
  }

  &.distrigo .name {
    color: green;
  }
}

.new-comment-message {
  width: calc(100% - 80px);
}

.send-button {
  background-color: #e3480a !important;
}
