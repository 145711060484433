.image-container {
    position: relative;
}

.preview-image {
    width: 100%;
}

.preview-image-wrapper {
    width: 100%;
    max-width: fit-content;
    position: absolute;
    z-index: 125;
    top: 40px;
    padding: 5px;
    background-color: #fff;
    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    border: 1px solid #fff;
    border-radius: 5px;
    right: 3px;
    &.hidden {
        display: none;
    }
}