$fontSize: 1.3rem;
$fontWeight: bold;
$paddingRight: 20px;
$flex: flex;
$justifyContentCenter: center;
$flexDirectionRow: row;
$paddingTop: 10px;

.CancelModal {
  max-width: 521px;
  width: 100% !important;
  .ModalHeader {
    display: $flex;
    font-weight: $fontWeight;
    font-size: 1.2rem;
    justify-content: flex-start;
  }
  .Container {
    align-items: center;
    display: $flex !important;
    flex-direction: column;
    font-size: 1rem;
    justify-content: $justifyContentCenter;
    padding-top: 40px;
  }
  .ServiceHeader {
    display: $flex;
    font-size: $fontSize;
    font-weight: $fontWeight;
    padding-right: 28px;
    padding-top: 4px;
  }
  .ServiceSection {
    display: $flex;
    flex-direction: $flexDirectionRow;
    font-size: 1rem;
    justify-content: flex-start;
    padding-bottom: 10px;
    padding-left: 40px;
  }
  .CategoryTypeSelect {
    align-items: flex-end;
    display: $flex;
    font-weight: $fontWeight;
    font-size: 1rem;
    margin-bottom: -2px;
  }
  .Dropdown {
    max-width: 176px;
    width: 100%;
  }
  .OrderTypeSection {
    display: $flex;
    flex-direction: $flexDirectionRow;
  }
  .OrderTypeHeader {
    display: $flex;
    font-size: $fontSize;
    font-weight: $fontWeight;
    padding-right: 26px;
    padding-left: 8px;
    padding-top: 5px;
  }

  .OrderTypeSelect {
    display: $flex;
    font-weight: $fontWeight;
  }

  .DatePickerSection {
    display: $flex;
    flex-direction: $flexDirectionRow;
    font-size: 1.2rem;
    font-weight: $fontWeight;
    padding-right: $paddingRight;
  }

  .DateHeader {
    display: $flex;
    font-size: $fontSize;
    font-weight: $fontWeight;
    justify-content: $justifyContentCenter;
    padding-left: 17px;
    padding-right: 18px;
    padding-top: 20px;
    white-space: nowrap;
  }
  .DatePicker {
    padding-top: $paddingTop;
  }
  .ModalContent {
    align-items: center;
    display: $flex !important;
    flex-direction: column;
    font-weight: $fontWeight;
    justify-content: $justifyContentCenter;
  }
}
