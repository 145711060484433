.logo {
  height: 3em;
  margin: auto 0;
  margin-right: 1.5em;
}

.topbar-dark-field * {
  background-color: #555 !important;
  color: #fff !important;
  border-color: #555 !important;
}

.customer-panel-info {
  margin: auto 0;
  color: #fff;
  font-size: 20px;
  font-weight: 200;
}
