.DeliveryRow {
    display: flex!important;
    max-width: none!important;
    overflow: initial!important;
}

.FormInputNumberStyle {
    max-width: none!important;
}

.TableFooterStyle {
    z-index: 9!important;
}

.UberSearchInput {
    display: inline-block!important;
    width: 200px!important;
}
