$fontSize: 1.3rem;
$fontWeight: bold;
$paddingRight: 20px;
$flex: flex;
$justifyContentCenter: center;
$flexDirectionRow: row;
$paddingTop: 10px;
$maxWidth: 521px;
$width: 100%;

.MoveModal {
    max-width: $maxWidth;
    width: $width !important;
    .Container {
        align-items: center;
        display: $flex !important;
        flex-direction: column;
        font-size: 1rem;
        justify-content: $justifyContentCenter;
        padding-top: 40px;
    }
    .WareSection {
        display: $flex;
        flex-direction: $flexDirectionRow;
        font-size: 1rem;
        justify-content: flex-start;
    }
    .WareDeliveryHeader {
        display: $flex;
        font-size: $fontSize;
        font-weight: $fontWeight;
        padding-right: 28px;
        padding-top: 4px;
    }
    .WareReceiveHeader {
        display: $flex;
        font-size: $fontSize;
        font-weight: $fontWeight;
        padding-right: 34px;
        padding-top: 4px;
    }
    .WareReceiveSelect {
        align-items: flex-end;
        display: $flex;
        font-weight: $fontWeight;
        font-size: 1rem;
        margin-bottom: -2px;
    }
    .WareReceiveClearNutton {
        margin-left: 4px !important;
        max-width: 91px;
        width: 100%;
    }
    .WareSelect {
        align-items: flex-end;
        display: $flex;
        font-weight: $fontWeight;
        font-size: 1rem;
        margin-bottom: -2px;
    }
    .ModalDropdown {
        max-width: 176px;
        width: 100%;
        z-index: 21;
    }
}

.ConfirmModal {
    max-width: $maxWidth;
    position: absolute;
    top: 250px;
    width: $width !important;
    .ConfirmModalContent {
        font-weight: $fontWeight;
        justify-content: $justifyContentCenter;
    }
}
