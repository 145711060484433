.ui.filter.input > input,
.ui.filter.input > input:focus {
  font-weight: 600;
  color: #c34711;
  font-size: 13px;
}

.ui.dic-select {
  width: 100%;
  min-width: 100px;
  color: #c34711;
  white-space: nowrap;
}

.ui.dic-select > i {
  z-index: 3;
}

.ui.filter.input {
  >input[type="date"]{
    width: 125px !important;
  }
}

.ui.filter.input.date-filter-init{
  >input{
    max-width: 58px !important;
    &:before{
      content: '...';
    }
  }
}

.ui.filter.input:not(.date-filter-init){
  >input{
    padding-right: 8px !important;
  }
}

.checkboxes-disabled {
  pointer-events: none;
}