.InvoiceSection {
    display: flex !important;
    flex-direction: column !important;
    .InvoiceNumber {
        padding-bottom: 20px;
        .InvoiceNumberInput {
            max-width: 208.3px!important;
            width: 100%!important;
        }
    }
    .InvoiceDateLabel {
        max-width: 172.688px!important;
        width: 100%!important;
    }
}

