.new-message{
    background-color: #fff;
    td, span{
        font-weight: bold;
    }
}

.old-message{
    background-color: #f2f2f2;

    .checkbox {
        pointer-events: none;
    }
}