.nice-label {
    .ui.image.label {
        max-width: 100%;
    }
}

.nice-label-flex.ui.label {
  display: inline-flex;
  align-items: center;
  max-width: 100%;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.8em;
  }

  .detail {
    flex: auto;
  }
}

.flex-max.ui.label {
  display: flex;
  text-align: center;

  span {
    flex: 2;
  }

  .detail {
    flex: 3;
  }
}
