.fields {
  .advanced-search-select {
    padding-right: 5px !important;

    .selection {
      min-width: auto !important;
    }
  }
}

div.ui.segment.toggle-checkbox.toggle-top {
  border: none;
}

.ui form {
  z-index: 21;
}
