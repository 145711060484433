.ChooseDownloadOptionModal {
    max-width: 511px;
    width: 100%;
    .ModalContent {
        align-items: center!important;
        display: flex!important;
        flex-direction: row!important;
        justify-content: center!important;
    }
    .ModalFooter {
        align-items: flex-end;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 1vh;
        margin-right: 0.2vw;
    }
}