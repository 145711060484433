.float-right {
  position: absolute !important;
  right: 0.5em !important;
}

.toggle-error * {
  color: #9f3a38 !important;
}

.margin-left {
  margin-left: 0.5em;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.super-field input,
.super-field .selection {
  height: 37px !important; /* Make input same height as dropdown */
  transition: background-color 300ms, border-color 300ms !important;
}

.super-field textarea {
  transition: background-color 300ms, border-color 300ms !important;
}

.super-field input[readonly],
.super-field textarea[readonly],
.read-only-disabled .selection {
  border-color: #fafafa !important;
  background-color: #fafafa !important;
  transition: background-color 300ms, border-color 300ms !important;
}

.read-only-disabled i.icon {
  display: none;
}

.super-field,
.read-only-disabled,
.read-only-disabled * {
  opacity: initial !important;
}

.super-toggle {
  width: 100%;
  display: inline-block;

  i,
  .loader {
    position: absolute !important;
    left: auto !important;
    right: 50px !important;
    top: 1px !important;
    display: inline !important;
  }

  .loader {
    right: 55px !important;
  }

  label {
    padding-left: 0 !important;
  }

  label:after {
    transition: background-color 0.3s ease, right 0.3s ease !important;
  }

  input ~ label:before {
    left: auto !important;
    right: 0;
  }

  input:checked ~ label:after {
    right: -0.15rem;
    left: auto !important;
  }

  input ~ label:after {
    right: 2.05rem;
    left: auto !important;
  }
}

.smart-label {
    margin:0em -0.5em 0.4em !important;
 }