.detail-card .card {
    margin-top: 13px !important;
}

.detail-card {
    padding-bottom: 0.5rem !important;
}

.detail-card .ui.header {
    margin-top: -31px !important;
}

.detail-card .ui.header span {
    background: white;
    padding: 0 5px;
    margin-left: -5px;
}