.ui.breadcrumb:first-child.accounting-crumbs {
    margin-top: 20px;
}

.toggle-checkbox.ui.segment {
    margin: 0 8px 0 0;
    padding: 0px 13px;
    display: flex;
    align-items: center;
    margin-left: auto;
    box-shadow: none;
    background-color: #fff;

    &.toggle-top {
        align-self: flex-end;
        padding: 8px 13px;
        margin-left: 7px;
    }

    label {
        color: #aaa;
    }
}

.text-area-description-wrapper {
    min-height: 135px;
}