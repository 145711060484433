.uber-dropdown {
  .description {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    font-size: 0.8em !important;
  }

  .results {
      overflow-y: auto;
      max-height: 250px;
  }
}
