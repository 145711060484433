.customer-search .results {
  width: 100% !important;
}

.ui.basic.fluid.button.radio-button {
  margin-bottom: 3px;
  text-align: left;
  width: 45%;
  max-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px !important;
  padding-bottom: 10px !important;

  &.validate-error {
    background-color: #ffe8e8 !important;
  }
}

.validate-error-container {
  display: flex;
  justify-content: space-between;

  .validate-error-message {
    color: #db6a64;
    font-style: italic;
  }
}
