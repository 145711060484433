.RenderedButtons {
    display: inline-block;
    position: absolute;
    top: 1.25em;
    right: 1em;
}

@media (max-width: 1450px) {
    .RenderedButtons {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        padding-left: 50px;
        position: static;
        top: 1.25em;
        right: 1em;
    }
}

@media (max-width: 1540px) {
    .RenderedMoreThanThreeButtons {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        padding-left: 5px;
        position: static;
        top: 1.25em;
        right: 1em;
    }
}

@media (min-width: 1541px) {
    .RenderedMoreThanThreeButtons {
        display: inline-block;
        position: absolute;
        top: 1.25em;
        right: 1em;
    }
}

.RenderedMoreThanThreeButtons > * {
    margin-left: 0px !important;
    margin-bottom: 4px !important;
}
