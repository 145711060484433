.dashboard-tables{
    .dashboard-table-wrapper{
        overflow-x: scroll;

        table{
            tbody{
                tr{
                    .col-dropdown-menu-sticky{
                        .ui.floating.dropdown > .menu{
                            top: -50px !important;
                        }
                    }
                }
            }
        }
    }
}