.input-number {
  input[readonly] ~ span {
    display: none;
  }
}
.input-number {
  button {
    display: block;
    position: absolute;
    height: 50%;
    right: 0;
    border: none;
    background: none;
    font-size: 0.6em;
    border-left: 1px solid rgba(0, 0, 0, 0.13);
    padding: 0 0.8em 0 0.6em;
    border-radius: 0 0.5em 0.5em 0;
  }
  button:hover {
    background-color: #e0e1e2;
    cursor: pointer;
  }
  input:not([readonly]) {
    padding-right: 2em !important;
  }
  i.icon {
    right: 1.2em !important;
  }
}
