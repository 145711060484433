.notifications-header-wrapper{
    cursor: pointer;
}

.notification-count{
    background-color: #e3480a;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    line-height: 19px;
    position: absolute;
    text-align: center;
    top: 2px;
    right: 7px;

    &.count-text-sm{
        font-size: 8px;
    }
    &.count-text-wrap{
        font-size: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 18px;
    }
}

.notifications-popup{
    .row{
        border-top: 1px solid #ccc;
        white-space: nowrap;
    }

    .row:first-child, .row:last-child {
        border-top: none;
    }
}

.no-lasts-messages {
    margin: 0 auto;
}

.notification-date-info {
    font-style: normal;
    font-size: 12px;
}