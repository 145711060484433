.dropdown-file-area {
    height: 250px;
    border: 2px dashed #000;
    border-radius: 8px;
    padding: 10px;
    font-size: 16px;
    position: relative;

    .list{
        position: absolute;
        top: 0;
        left: 10px;
    }
  }
  
  .dropdown-file-area--dragging {
    background-color: lightblue;
  }
  
  .dropdown-file-area__contents {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .dropdown-file-area__file-name {
    font-weight: 700;
  }
  
  .dropdown-file-area__text-info {
    font-size: 12px;
    display: block;
  }
  