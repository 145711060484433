.text-editor {
  background-color: #f1f2f5;
  padding: 10px !important;
  font-size: 15px;
  min-height: 150px;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-top: 10px;
  box-shadow: 0px 0px 10px 0px rgba(93, 93, 101, 1);
  width: 100%;
}

.toolbar {
  margin-bottom: 15px;
}
