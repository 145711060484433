@media (max-width: 1480px) {
  .add-order-table {
    overflow-x: scroll;
    padding-bottom: 55px;
  }
}

.bonus-order-import-table-wrapper {
  display: block;
  max-height: 148px;
  overflow-y: scroll;
}
