.ui.modal .scrolling.content {
  height: calc(75vh - 10em) !important;
}

.right,
.left {
  margin-top: 10px;
  margin-bottom: 10px;
}

.comment-wrapper {
  padding: 8px;
  border-radius: 8px;
  font-size: 15px;
}

.right {
  justify-content: end !important;

  .comment-wrapper {
    border-bottom-right-radius: 0;
    background-color: #e3480a;
    color: #fff;
  }
}

.left {
  justify-content: start !important;

  .comment-wrapper {
    border-bottom-left-radius: 0;
    background-color: #bdbec0;
  }
}

.info {
  position: absolute;
  top: -10px;
  font-size: 10px;
}

.send-container {
  display: flex;
  align-items: center !important;
  justify-content: space-between !important;
  gap: 10px;

  div.textarea-wrapper {
    width: calc(100% - 100px);
  }
}

.send-button {
  background-color: #e3480a !important;
  max-height: 35px;
}

textarea.new-comment-message {
  width: 100%;
  padding: 10px;
  border: none;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.13) inset;

  &:focus-visible {
    outline: none;
    box-shadow: 0px 0px 0px 1px #51a7e8 inset;
  }
}
