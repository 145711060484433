.search-control {
  .input {
    width: 100%;
  }

  &.error {
    .input input {
      background: #fff6f6 !important;
      border-color: #e0b4b4 !important;
      color: #9f3a38 !important;
      box-shadow: none !important;
    }
  }
}
