div.add-reference-search {
  display: inline-block;
  .ui.icon.input {
    input {
      width: 200px;
    }
    i.search.icon {
      display: none;
    }
  }
}

div.add-refference-red-error {
  min-height: 30px;
  color: #db6a64;
}

div.add-refference-info-error {
  min-height: 30px;
  color: unset;
}
