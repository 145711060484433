@import "~react-redux-toastr/src/styles/index";

html,
body,
#root,
#root > div {
  overflow: hidden;
}

body ::-webkit-scrollbar-thumb {
  border-radius: 0 !important;
}

.ui.sidebar.visible ~ .pusher {
  width: calc(100% - 230px);
  height: calc(100vh - 10px);
  overflow: auto;
}

.ui.sidebar.visible ~ .pusher > .segment {
  height: 110%;
}

.text-green {
  color: #008000 !important;
}

.text-red {
  color: #ff0000 !important;
}

.ui.dropdown .menu > .disabled.item {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}

.col-dropdown-menu-sticky {
  position: sticky;
  right: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  background-color: #fff;

  .dropdown {
    .menu {
      margin-right: 30px !important;
    }
  }
}

.col-dropdown-menu-sticky:focus-within {
  z-index: 50;
  overflow: visible;
}

.dropdown-up-direction {
  .results {
    top: unset !important;
    bottom: 3em;
  }
}

.textarea-field,
.textarea-wrapper textarea {
  width: 100% !important;
  margin: 0px;
  max-width: 100%;
  padding: 0.8em;
  border: 1px solid rgba(0, 0, 0, 0.13);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.2307em;
  transition:
    box-shadow 0.1s ease 0s,
    border-color 0.1s ease 0s;
  box-shadow: none;
  height: 75px;
  resize: none;
  outline: none;
}

.top-search-header {
  .search.icon {
    padding: 7px 5px !important;
    line-height: initial;
  }
}

.no-wrap {
  white-space: nowrap;
}

.uber-content {
  overflow: auto;
  height: calc(100vh - 127px);
  margin: -0.5rem;

  &.overflow-visible {
    overflow: visible;
  }

  > .ui.grid {
    margin: 0;
  }
}

.ui.fluid.container ~ .ui.divider ~ .uber-content {
  max-height: calc(100vh - 173px);
}

.ui.fluid.container ~ .ui.menu ~ .uber-content {
  height: calc(100vh - 241px);
}

.uber-table {
  overflow-x: auto;
  height: calc(100vh - 150px);

  table {
    border-top: none !important;
    border-bottom: none !important;
    padding-top: 0px !important;
  }

  .ui.table {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  th {
    position: sticky;
    z-index: 10;
  }

  thead {
    tr th {
      top: 40px;
      z-index: 20;
    }

    tr:first-child th {
      top: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.13);
    }
  }

  tfoot {
    tr th {
      bottom: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    }
  }

  tbody tr td {
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .docked {
    opacity: 0.5;
    position: absolute;
    right: 1em;
    margin-top: -28px;
    height: 28px;
    padding: 4px 8px;
    border: 1px solid rgba(0, 0, 0, 0.13);
    border-bottom: none;
    background-color: white;
    border-radius: 5px 5px 0 0;
    z-index: 100;
    transition: opacity 0.3s;
  }

  .sort-icon {
    color: #c34711;
    margin-left: 5px;
    font-size: 0.9em;

    &:hover {
      color: #000;
    }
  }
}

.uber-table:hover .docked {
  opacity: 1;
}

@keyframes icon-loading-rv {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

i.icon.loading-rv {
  animation: icon-loading-rv 1.5s linear infinite;
}

.display-none {
  display: none !important;
}

.hide-tab-menu {
  > .ui.secondary.menu {
    display: none;
  }
}

.ui.breadcrumb {
  .divider {
    margin: 0 0.5em !important;
  }
}

.collective-corrections-modal-header .content {
  width: 100%;
}

.user-disable-interaction {
  pointer-events: none;
}
